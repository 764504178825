<script>
    import Utils from '@/modules/utils';
    import I18n from '@/modules/translations';

    import {mapStore} from '@/stores/mapStore';

    import Autocompletion from '@/components/search/Autocompletion.svelte';

    import AnalyticsService from '../../../assets/javascripts/modules/analyticsService';

    const onSubmit = (selectedLocation) => {
        if (selectedLocation) {
            AnalyticsService.trackHomeShowPlaceSelected();
            AnalyticsService.trackSearch('Home page', undefined, `${selectedLocation.value} (${selectedLocation.placeType})`, 1);
        }

        mapStore.mapLocation = selectedLocation;

        setTimeout(() => Utils.scrollTo('home-map'), 100);
    };
</script>

<div class="home-search-form">
    <form id="global-search"
          class="search"
          accept-charset="UTF-8"
          autocomplete="off"
          novalidate="novalidate"
          onsubmit={onSubmit}>
        <Autocompletion id="home-search"
                        placeholder={I18n.t('js.views.home.search.placeholder')}
                        searchButton={I18n.t('js.views.home.search.button')}
                        searchFeatures={['location']}
                        autoFocus={true}
                        onSubmit={onSubmit}/>
    </form>
</div>
